type Listener = (...args: any[]) => void;

class EventEmitter {
	private events: { [key: string]: Listener[] } = {};

	on(event: string, listener: Listener) {
		if (!this.events[event]) {
			this.events[event] = [];
		}
		this.events[event].push(listener);
	}

	emit(event: string, ...args: any[]) {
		if (this.events[event]) {
			this.events[event].forEach((listener) => listener(...args));
		}
	}

	off(event: string, listenerToRemove: Listener) {
		if (this.events[event]) {
			this.events[event] = this.events[event].filter(
				(listener) => listener !== listenerToRemove
			);
		}
	}
}

export const globalEventEmitter = new EventEmitter();
