"use client";

import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	ReactNode,
	useCallback,
	useMemo,
} from "react";
import { UserProfile, useUser } from "@auth0/nextjs-auth0/client";
import { getMixPanelClient } from "@/app/mixpanel";
import { AuthModal } from "./AuthModal";
import { BetaAccessModal } from "../BetaAccess/BetaAccessModal";
import { makeToastError } from "@/utils";

interface CustomClaims {
	"skibs/roles"?: string[];
}

type User = UserProfile & CustomClaims;

interface UserData {
	credits: number;
}

interface AuthContextType {
	isAuthenticated: boolean;
	isLoading: boolean;
	error: Error | undefined;
	openAuthModal: (
		message?: string,
		error?: string,
		errorDescription?: string
	) => void;
	closeAuthModal: () => void;
	// openBetaRequestModal: () => void;
	user: User | undefined;
	userData: UserData | null;
	refreshUserData: (force?: boolean) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const {
		user: authUser,
		isLoading: authLoading,
		error: authError,
	} = useUser();
	const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
	const [isBetaRequestModalOpen, setIsBetaRequestModalOpen] = useState(false);
	const [authModalError, setAuthModalError] = useState<{
		error: string;
		errorDescription: string;
	} | null>(null);
	const [userData, setUserData] = useState<UserData | null>(null);
	const [lastFetchTime, setLastFetchTime] = useState<number>(0);
	const [isLoadingUserData, setIsLoadingUserData] = useState(false);

	const user = authUser as User | undefined;
	const mp = getMixPanelClient();

	const fetchUserData = useCallback(async (): Promise<UserData | null> => {
		if (user?.email_verified) {
			try {
				const response = await fetch("/api/v1/user/credits/me");
				if (response.ok) {
					const data = await response.json();
					return { credits: data.credits };
				} else {
					console.error("Failed to fetch user data");
				}
			} catch (error) {
				console.error("Error fetching user data:", error);
			}
		}
		return null;
	}, [user?.email_verified]);

	const refreshUserData = useCallback(
		async (force = false) => {
			const currentTime = Date.now();
			if (force || currentTime - lastFetchTime > 60000) {
				// Cache for 1 minute
				setIsLoadingUserData(true);
				const newUserData = await fetchUserData();
				if (newUserData) {
					setUserData(newUserData);
					setLastFetchTime(currentTime);
				}
				setIsLoadingUserData(false);
			}
		},
		[fetchUserData, lastFetchTime]
	);

	useEffect(() => {
		if (user?.email_verified && !userData && !isLoadingUserData) {
			refreshUserData(true);
		}
	}, [user?.email_verified, userData, refreshUserData, isLoadingUserData]);

	const openAuthModal = useCallback(
		(message?: string, error?: string, errorDescription?: string) => {
			if (message) makeToastError({ message });
			setIsAuthModalOpen(true);
			if (error && errorDescription) {
				setAuthModalError({ error, errorDescription });
			}
		},
		[]
	);

	const closeAuthModal = useCallback(() => {
		setIsAuthModalOpen(false);
		const newUrl = window.location.pathname;
		window.history.replaceState({}, "", newUrl);
		setAuthModalError(null);
	}, []);

	// const openBetaRequestModal = useCallback(() => {
	// 	setIsBetaRequestModalOpen(true);
	// }, []);

	// const closeBetaRequestModal = useCallback(() => {
	// 	setIsBetaRequestModalOpen(false);
	// }, []);

	const contextValue = useMemo(
		() => ({
			isAuthenticated: !!user?.email_verified,
			isLoading: authLoading || isLoadingUserData,
			error: authError,
			openAuthModal,
			closeAuthModal,
			// openBetaRequestModal,
			user,
			userData,
			refreshUserData,
		}),
		[
			user,
			authLoading,
			isLoadingUserData,
			authError,
			openAuthModal,
			closeAuthModal,
			// openBetaRequestModal,
			userData,
			refreshUserData,
		]
	);

	return (
		<AuthContext.Provider value={contextValue}>
			{children}
			<AuthModal
				isOpen={isAuthModalOpen}
				onOpenChange={closeAuthModal}
				error={authModalError?.error}
				errorDescription={authModalError?.errorDescription}
			/>
			{/* <BetaAccessModal
				isOpen={isBetaRequestModalOpen}
				onOpenChange={closeBetaRequestModal}
			/> */}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
