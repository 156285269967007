export function generateMTLContent(
	materialName: string = "material_0",
	textureName: string = "material_0.png"
): string {
	return `newmtl ${materialName}
Ka 1.00000000 1.00000000 1.00000000
Kd 0.40000000 0.40000000 0.40000000
Ks 0.50196078 0.50196078 0.50196078
Ns 250.00000000
map_Kd ${textureName}`;
}
