import mixpanel, { Dict, Query } from "mixpanel-browser";
import { EventName } from "./analytics-types";

let mixpanelInstance: MixPanel | null = null;

export const getMixPanelClient = () => {
	if (!mixpanelInstance) {
		mixpanelInstance = new MixPanel();
	}
	return mixpanelInstance;
};

export class MixPanel {
	constructor() {
		const isProd = process.env.NODE_ENV === "production";
		mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!, {
			api_host: process.env.NEXT_PUBLIC_MIXPANEL_API!,
			debug: !isProd,
			track_pageview: true,
			persistence: "localStorage",
			ignore_dnt: true,
		});
	}

	getDistinctId() {
		return mixpanel.get_distinct_id();
	}

	identify(id: string) {
		mixpanel.identify(id);
	}

	alias(id: string, userId: string) {
		mixpanel.alias(id, userId);
	}

	track(event: EventName, props?: Dict) {
		mixpanel.track(event, props);
	}

	track_links(query: Query, name: string) {
		mixpanel.track_links(query, name, {
			referrer: document.referrer,
		});
	}

	set(props: Dict) {
		mixpanel.people.set(props);
	}

	reset() {
		mixpanel.reset();
	}
}
