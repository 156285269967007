import { Button } from "@nextui-org/react";
import Link from "next/link";
import toast, { ErrorIcon, Toast } from "react-hot-toast";
import { IoClose } from "react-icons/io5";

interface MakeToastErrorOptions {
	message: string;
	duration?: number;
	returnButton?: boolean;
}

export const makeToastError = ({
	message,
	duration = Infinity,
	returnButton,
}: MakeToastErrorOptions) => {
	toast(
		(t: Toast) => (
			<div className="w-64">
				<div
					style={{
						marginRight: "14px",
					}}
					className="text-center"
				>
					<p className="text-white text-left">{message}</p>
					{returnButton && (
						<div className="w-full">
							<Button
								variant="light"
								size="sm"
								className="mx-auto font-medium text-medium"
								as={Link}
								href="/"
								onPress={() => toast.dismiss(t.id)}
							>
								Return
							</Button>
						</div>
					)}
				</div>
				<Button
					isIconOnly
					onPress={() => toast.dismiss(t.id)}
					variant="light"
					className="absolute right-1 top-1"
					size="sm"
					radius="full"
				>
					<IoClose size={20} />
				</Button>
			</div>
		),
		{
			duration: duration,
			icon: <ErrorIcon />,
			style: {
				borderRadius: "10px",
				background: "#730000",
				color: "#fff",
			},
		}
	);
};
