import { NextRequest, NextResponse } from "next/server";
import * as Sentry from "@sentry/nextjs";
import { getSession } from "@auth0/nextjs-auth0/edge";

export type HandlerContext = {
	params?: Record<string, string | string[]>;
};

type RouteHandler = (
	req: NextRequest,
	ctx: HandlerContext
) => Promise<NextResponse | Response>;

export function withSentry(handler: RouteHandler): RouteHandler {
	return async (req: NextRequest, ctx: HandlerContext) => {
		try {
			const res = NextResponse.next();
			const session = await getSession(req, res);

			if (session?.user) {
				// Set user context for Sentry
				Sentry.setUser({
					id: session.user.sub,
					email: session.user.email,
					username: session.user.nickname,
				});

				const response = await handler(req, ctx);
				return response;
			}

			return await handler(req, ctx);
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		} finally {
			Sentry.setUser(null);
		}
	};
}
