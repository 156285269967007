export enum EventName {
	PageView = "Page View",
	SignIn = "Sign In",
	SignInStart = "Sign In Start",
	SignUp = "Sign Up",
	SignUpStart = "Sign Up Start",
	SignOut = "Sign Out",
	ButtonClick = "Button Click",
	ModalClose = "Modal Close",
	FormOpen = "Form Open",
	FormSubmit = "Form Submit",
	Download = "Download",
	RedoCharacter = "Redo Character",
	ConfirmQuickRedo = "Confirm Quick Redo",
	CancelQuickRedo = "Cancel Quick Redo",
	HelpTooltip = "Help Tooltip",
	HelpTooltipLinkClick = "Help Tooltip Link Click",
	DiscordLinkClick = "Discord Link Click",
	UploadFile = "Upload File",
	CancelCreation = "Cancel Upload",
	StartCreation = "Start Creation",
	BackCreation = "Back Creation",
	StartRigging = "Start Rigging",
	StartAnimation = "Start Animation",
	ToggleAudio = "Toggle Audio",
	RedoMenuOpened = "Redo Menu Opened",
	OpenRedoDropdown = "Open Redo Dropdown",
	OpenRecordFormatDropdown = "Open Record Format Dropdown",
	SelectRecordFormat = "Select Record Format",
	DownloadRecordedVideo = "Download Recorded Video",
	ClickOnLogo = "Click On Logo",
	SelectTemplateCharacter = "Select Template Character",
	OpenMotionTransferDropdown = "Open Motion Transfer Dropdown",
	SelectMotionTransfer = "Select Motion Transfer",
	ExportVideo = "Export Video",
	OpenGeneration = "Open Generation",
	CloseGeneration = "Close Generation",
	GenerationFavorite = "Generation Favorite",
	StartEditingTitle = "Start Editing Title",
	FinishEditingTitle = "Finish Editing Title",
	GenerationChangeRedoCharacter = "Generation Change Redo Character",
	GenerationStartMovingCamera = "Generation Start Moving Camera",
	GenerationFinishMovingCamera = "Generation Finish Moving Camera",
	GenerationDisplaySidebar = "Generation Display Sidebar",
	GenerationDisplaySpecificAction = "Generation Display Specific Action",
	GenerationTooltipOpen = "Generation Tooltip Open",
	GenerationOpenMotionDropdown = "Generation Open Motion Dropdown",
	GenerationNewAnimation = "Generation New Animation",
	GenerationNewRigging = "Generation New Rigging",
	GenerationNewCharacterEdit = "Generation New Character Edit",
	GenerationOpenBackToCraftingDropdown = "Generation Open Back To Crafting Dropdown",
	GenerationOpenDownloadDropdown = "Generation Open Download Dropdown",
}

export enum FeatureName {
	Auth = "Auth",
	PageView = "Page View",
	Record = "Record",
	CharacterCreation = "Character Creation",
	CharacterRedo = "Character Redo",
	CharacterRigging = "Character Rigging",
	CharacterAnimation = "Character Animation",
	TemplateCharacter = "Template Character",
	MotionTransfer = "Motion Transfer",
}
