import * as Sentry from "@sentry/nextjs";
import { NextResponse } from "next/server";

export class ApiError extends Error {
	constructor(
		public statusCode: number,
		message: string,
		public details?: any
	) {
		super(message);
		this.name = "ApiError";
	}
}

export async function handleError(error: unknown) {
	console.error("API Error:", error);

	if (error instanceof ApiError) {
		// Capture unknown errors in Sentry
		Sentry.captureException(error);
		return NextResponse.json(
			{
				error: error.message,
				details: error.details,
			},
			{ status: error.statusCode }
		);
	}

	// Capture unknown errors in Sentry
	Sentry.captureException(error);

	return NextResponse.json({ error: "Internal Server Error" }, { status: 500 });
}
