import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import { makeToastError } from "./makeToastError";

export const trimVideo = async (
	file: File,
	ffmpeg: FFmpeg,
	maxDuration: number = 20
): Promise<File> => {
	if (!ffmpeg.loaded) {
		throw new Error("FFmpeg is not loaded");
	}

	try {
		// Write the input file to FFmpeg's virtual filesystem
		const inputFileName =
			"input" + file.name.substring(file.name.lastIndexOf("."));
		const outputFileName = "output.mp4";

		await ffmpeg.writeFile(inputFileName, await fetchFile(file));

		// Construct FFmpeg command to trim video
		const ffmpegCommand = [
			"-i",
			inputFileName,
			"-t",
			maxDuration.toString(),
			"-c:v",
			"libx264", // Use H.264 codec for video
			"-c:a",
			"aac", // Use AAC codec for audio
			"-strict",
			"experimental",
			"-b:a",
			"128k", // Audio bitrate
			"-r",
			"30", // Frame rate
			outputFileName,
		];

		// Execute the FFmpeg command
		await ffmpeg.exec(ffmpegCommand);

		// Read the output file
		const data = await ffmpeg.readFile(outputFileName);
		const trimmedBlob = new Blob([data], { type: "video/mp4" });

		// Create a new File from the blob
		const trimmedFile = new File([trimmedBlob], file.name, {
			type: "video/mp4",
		});

		return trimmedFile;
	} catch (error) {
		console.error("Error trimming video:", error);
		throw error;
	}
};
