import React from "react";
import {
	Modal,
	ModalContent,
	ModalHeader,
	Button,
	ModalBody,
} from "@nextui-org/react";
import { IoClose } from "react-icons/io5";
import { useAuth } from "./AuthContext";
import { getMixPanelClient } from "@/app/mixpanel";

interface AuthModalProps {
	isOpen: boolean;
	onOpenChange: () => void;
	error?: string | null;
	errorDescription?: string | null;
}

const TextDivider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
	<div className="flex items-center my-4">
		<div className="flex-grow border-t border-gray-300"></div>
		<span className="flex-shrink mx-4 text-gray-300 text-sm">{children}</span>
		<div className="flex-grow border-t border-gray-300"></div>
	</div>
);

export const AuthModal: React.FC<AuthModalProps> = ({
	isOpen,
	onOpenChange,
	error,
	errorDescription,
}) => {
	const mp = getMixPanelClient();

	return (
		<Modal
			isOpen={isOpen}
			onOpenChange={onOpenChange}
			placement="center"
			backdrop="blur"
			closeButton={
				<Button isIconOnly variant="light">
					<IoClose size={24} />
				</Button>
			}
		>
			<ModalContent className="p-2 sm:p-4">
				{(onClose) => (
					<>
						<ModalHeader className="flex flex-col gap-1">
							<span className="text-xl sm:text-2xl text-center font-semibold">
								Welcome to skibs
							</span>
						</ModalHeader>
						<ModalBody>
							<p className="text-center text-sm sm:text-base mb-2 sm:mb-5">
								Create and customize your own skibs with an account.
							</p>
							<div className="w-full text-center space-y-4 flex flex-col">
								{/* <Button
									className="w-full mx-auto"
									color="primary"
									size="lg"
									onPress={() => {
										openBetaRequestModal();
										onClose();
									}}
								>
									<span className="font-medium">Request beta access</span>
								</Button>
								<TextDivider>or</TextDivider>
								<p className="text-sm">Already part of our beta crew?</p> */}
								<Button
									href={`/api/auth/login?distinctId=${mp.getDistinctId()}`}
									as="a"
									className="w-2/3 sm-w-5/6 mx-auto border-purple-400 text-purple-400 h-10 sm:h-12 text-sm sm:text-base"
									color="secondary"
									variant="ghost"
									size="lg"
								>
									<span className="font-medium">Sign in</span>
								</Button>
								<Button
									href={`/api/auth/signup?distinctId=${mp.getDistinctId()}`}
									as="a"
									className="w-2/3 sm-w-5/6 mx-auto h-10 sm:h-12 text-sm sm:text-base"
									style={{
										backgroundColor: "hsl(var(--nextui-content2))",
									}}
									variant="flat"
									size="lg"
								>
									<span className="font-medium">Create an account</span>
								</Button>
								{error && errorDescription && (
									<div className="text-center text-danger-500">
										<span className="block">{errorDescription}</span>
									</div>
								)}
							</div>
							<Button
								className="w-full mx-auto mt-2 sm:mt-6"
								variant="light"
								color="primary"
								onPress={() => {
									sessionStorage.setItem("authModalSeen", "true");
									onClose();
								}}
							>
								<span className="relative after:absolute after:bg-white after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300 text-wrap">
									Just curious? Explore examples without an account
								</span>
							</Button>
						</ModalBody>
					</>
				)}
			</ModalContent>
		</Modal>
	);
};
